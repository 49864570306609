import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import util from "util"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"

import axentix from "axentix/dist/css/axentix.css"
import grix from "axentix/dist/css/grix.css"


const IndexPage = () => {
  const { register, handleSubmit, getValues, setValue, watch, errors } = useForm()

  // 1時間降水量のAPIのリクエストURL
  const [apiRequestUrlPre1h00, setApiRequestUrlPre1h00] = useState("")
  // 最低気温のAPIのリクエストURL
  const [apiRequestUrlMntemsadext00, setApiRequestUrlMntemsadext00] = useState("")
  // 最高気温のAPIのリクエストURL
  const [apiRequestUrlMxtemsadext00, setApiRequestUrlMxtemsadext00] = useState("")
  // 検索オプション
  const [searchMode, setSearchMode] = useState("address")
  // 検索住所
  const [searchAddress, setSearchAddress] = useState("")
  // 検索緯度
  const [searchLatitude, setSearchLatitude] = useState(0)
  // 検索経度
  const [searchLongitude, setSearchLongitude] = useState(0)
  // 検索半径
  const [searchRadius, setSearchRadius] = useState("10000")
  // 検索レスポンスコード
  const [searchResponseCode, setSearchResponseCode] = useState("")

  // 気象データすべて
  const [weatherData, setWeatherData] = useState({})
  // 都道府県
  const [prefecture, setPrefecture] = useState("")
  // 観測所
  const [point, setPoint] = useState("")
  // 現在の降水量
  const [currentPrecipitation, setCurrentPrecipitation] = useState(0)
  // 最低気温
  const [minimumTemperature, setMinimumTemperature] = useState(0)
  // 最高気温
  const [maximumTemperature, setMaximumTemperature] = useState(0)
  // 1時間降水量の観測日時
  const [currentTime, setCurrentTime] = useState("")
  // 最低気温の観測日時
  const [currentTimeMinimumTemperature, setCurrentTimeMinimumTemperature] = useState("")
  // 最高気温の観測日時
  const [currentTimeMaximumTemperature, setCurrentTimeMaximumTemperature] = useState("")
  // 1時間降水量を気象庁から取得した日時
  const [createTime, setCreateTime] = useState("")
  // 最低気温を気象庁から取得した日時
  const [createTimeMinimumTemperature, setCreateTimeMinimumTemperature] = useState("")
  // 最高気温を気象庁から取得した日時
  const [createTimeMaximumTemperature, setCreateTimeMaximumTemperature] = useState("")

  // 検索ボタンの無効化
  const [buttonLock, setButtonLock] = useState(false)

  useEffect(() => {
    setApiRequestUrlPre1h00(getApiRequestUrl())
    setApiRequestUrlMntemsadext00(getApiRequestUrl("mntemsadext00"))
    setApiRequestUrlMxtemsadext00(getApiRequestUrl("mxtemsadext00"))
  }, [searchMode, searchAddress, searchLatitude, searchLongitude, searchRadius])


  // APIリクエストURLを返す
  const getApiRequestUrl = (dataType) => {
    const urlPath = "http://157.245.144.234/api"
    //const urlPath = `http://localhost/api`

    let url = urlPath
    switch (dataType) {
      case "mntemsadext00":
        url += `/mntemsadext00`
        break
      case "mxtemsadext00":
        url += `/mxtemsadext00`
        break
      default:
        url += `/pre1h00`
    }

    if (searchMode === "address") {
      return `${url}/address/${searchAddress}/${searchRadius}`
    } else {
      return `${url}/${searchLatitude}/${searchLongitude}/${searchRadius}`
    }
  }

  // 検索オプションの変更
  const onChangeSearchMode = () => {
    setValue("address", "")
    setSearchAddress("")
    setSearchLatitude(0)
    setSearchLongitude(0)
    setSearchMode(getValues("searchMode"))
  }

  // 住所の変更
  const onChangeAddress = () => {
    if (searchMode === "address") {
      setSearchAddress(getValues("address"))
    } else {
      const address = getValues("address")
      if (address !== "") {
        const lat_lng = address.split(",")
        if (lat_lng.length > 1) {
          const latitude = Number(lat_lng[0].trim())
          const longitude = Number(lat_lng[1].trim())

          if (latitude !== undefined || isNaN(latitude)) {
            setSearchLatitude(latitude)
          }
          if (longitude !== undefined || isNaN(longitude)) {
            setSearchLongitude(longitude)
          }
        }
      }
    }
  }

  // 検索半径の変更
  const onChangeSearchRadius = () => {
    setSearchRadius(getValues("searchRadius"))
  }

  // 住所、緯度・経度の補足事項
  const AddressMsg = () => {
    if (searchMode === "address") {
      return (
        <span>都道府県から入力します。住所によっては気象情報を取得できないことがあります。その場合は緯度・経度で検索してください。</span>
      )
    }

    if (searchMode === "lat_lng") {
      return (
        <span>Google Mapでマップ上を右クリック。コンテキストメニューの一番上の数値をクリックすると緯度・経度をコピーできます。</span>
      )
    }
  }

  // エラーメッセージ
  const ErrorMsg = () => {
    if (searchResponseCode === "000") {
      return (
        <div className="col-xs6 amaranth" style={{ padding: "1rem", marginTop: "1rem" }}>住所、緯度・経度を入力してください。</div>
      )
    }
    if (searchResponseCode === "201") {
      return (
        <div className="col-xs6 amaranth" style={{ padding: "1rem", marginTop: "1rem" }}>検索半径の圏内に観測所が見つかりませんでした。</div>
      )
    }
    if (searchResponseCode === "202") {
      return (
        <div className="col-xs6 amaranth" style={{ padding: "1rem", marginTop: "1rem" }}>住所の気象情報を取得できませんでした。<br />緯度・経度で検索してください。
        </div>
      )
    }
    if (searchResponseCode === "401") {
      return (
        <div className="col-xs6 red dark-3" style={{ padding: "1rem", marginTop: "1rem" }}>アメダス所在地情報を取得できませんでした。</div>
      )
    }
    if (searchResponseCode === "402") {
      return (
        <div className="col-xs6 red dark-3" style={{ padding: "1rem", marginTop: "1rem" }}>気象情報を取得できませんでした。</div>
      )
    }

    return false
  }

  // 住所から気象情報を取得する
  const onSubmit1 = (data) => {
    if (
      searchMode === "address" && searchAddress === "" ||
      searchMode === "lat_lng" && searchLatitude <= 0 && searchLongitude <= 0
    ) {
      setSearchResponseCode("000")
      return false
    }

    setWeatherData({})

    setButtonLock(true)

    /* todo: APIを並列で呼び出すと住所変換コンポーネント(imi-enrichment-address-2.0.0) でデータベスロックのエラーが出る。
     * とりあえずサンプルで見れれば良いレベルなので酷い実装は目を瞑る :)
     */

    // 1時間降水量
    axios.get(apiRequestUrlPre1h00)
      .then(response => {
        setSearchResponseCode(response.data.code)

        // 気象データを取得した
        if (response.data.code === "101") {
          const data = response.data.weatherData[0]
          const currentTime = `${data.current_time_year}年 ${data.current_time_month}月 ${data.current_time_day}日 ${data.current_time_hour}時 ${data.current_time_minutes}分`
          const createTime = moment(data.createdAt).locale("ja").format("YYYY年 M月 D日 H時 m分")

          setWeatherData({ data })
          setPrefecture(data.prefecture)
          setPoint(data.point)
          setCurrentPrecipitation(data.current_value)
          setCurrentTime(currentTime)
          setCreateTime(createTime)
        } else {
          setPrefecture("")
          setPoint("")
          setCurrentPrecipitation(0)
          setCurrentTime("")
          setCreateTime("")
        }

        // 最低気温
        axios.get(apiRequestUrlMntemsadext00)
          .then(response => {
            setSearchResponseCode(response.data.code)

            // 気象データを取得した
            if (response.data.code === "101") {
              const data = response.data.weatherData[0]
              const currentTime = `${data.current_time_year}年 ${data.current_time_month}月 ${data.current_time_day}日 ${data.current_time_hour}時 ${data.current_time_minutes}分`
              const createTime = moment(data.createdAt).locale("ja").format("YYYY年 M月 D日 H時 m分")

              setMinimumTemperature(data.today_lowest_temperature)
              setCurrentTimeMinimumTemperature(currentTime)
              setCreateTimeMinimumTemperature(createTime)
            } else {
              setMinimumTemperature(0)
              setCurrentTimeMinimumTemperature("")
              setCreateTimeMinimumTemperature("")
            }

            // 最高気温
            axios.get(apiRequestUrlMxtemsadext00)
              .then(response => {
                setSearchResponseCode(response.data.code)

                // 気象データを取得した
                if (response.data.code === "101") {
                  const data = response.data.weatherData[0]
                  const currentTime = `${data.current_time_year}年 ${data.current_time_month}月 ${data.current_time_day}日 ${data.current_time_hour}時 ${data.current_time_minutes}分`
                  const createTime = moment(data.createdAt).locale("ja").format("YYYY年 M月 D日 H時 m分")

                  setMaximumTemperature(data.today_highest_temperature)
                  setCurrentTimeMaximumTemperature(currentTime)
                  setCreateTimeMaximumTemperature(createTime)
                } else {
                  setMaximumTemperature(0)
                  setCurrentTimeMaximumTemperature("")
                  setCreateTimeMaximumTemperature("")
                }
                setButtonLock(false)
              })
              .catch(err => {
                setButtonLock(false)
                console.log(`err: ` + err, "err")
              })
          })
          .catch(err => {
            setButtonLock(false)
            console.log(`err: ` + err, "err")
          })
      })
      .catch(err => {
        setButtonLock(false)
        console.log(`err: ` + err, "err")
      })
  }


  return (
    <Layout>
      <SEO title="Home" />
      <h3 style={{ width: "100%", borderBottom: "2px solid", marginBottom: "2rem" }}>最新の気象情報</h3>
      <form onSubmit={handleSubmit(onSubmit1)}>
        <div className="grix xs6">
          <div className="col-xs6">
            <div className="card shadow-1 rounded-3">
              <div className="card-content">
                <div className="grix xs6">
                  <div className="col-xs2">
                    <select
                      name="searchMode"
                      ref={register}
                      onChange={onChangeSearchMode}
                      className="form-control rounded-1"
                    >
                      <option value="address">住所で検索</option>
                      <option value="lat_lng">緯度・経度で検索</option>
                    </select>
                  </div>
                  <div className="col-xs4">
                    <input
                      name="address"
                      ref={register}
                      placeholder={searchMode === "address" ? "滋賀県大津市瀬田大江町横谷1-5" : "34.96396949106391, 135.94081200004356"}
                      className="form-control rounded-1"
                      style={{ width: "100%" }}
                      onChange={onChangeAddress}
                    />
                  </div>
                  <div className="col-xs6 pos-xs3" style={{ fontSize: "small", marginBottom: "1rem" }}>
                    <AddressMsg />
                  </div>
                  <div className="col-xs2 vself-center txt-right">検索半径<br /><span
                    style={{ fontSize: "small" }}>最寄り観測所を探す範囲</span></div>
                  <div className="col-xs2">
                    <select
                      name="searchRadius"
                      ref={register}
                      defaultValue="10000"
                      onChange={onChangeSearchRadius}
                      className="form-control rounded-1"
                    >
                      <option value="1000">1km</option>
                      <option value="5000">5km</option>
                      <option value="10000">10km (デフォルト)</option>
                      <option value="20000">20km</option>
                      <option value="30000">30km</option>
                      <option value="50000">50km</option>
                      <option value="100000">100km</option>
                      <option value="200000">200km</option>
                    </select>
                  </div>
                  <div className="col-xs2 pos-xs5">
                    <input
                      type="submit"
                      value="検索"
                      className="btn rounded-1 blue press"
                      style={{ width: "100%" }}
                      disabled={buttonLock}
                    />
                  </div>
                  <div className="col-xs6">
                    <ErrorMsg />
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="card-footer">
                <p><b>APIリクエストURL 1時間降水量</b><br />{apiRequestUrlPre1h00}</p>
                <p><b>APIリクエストURL 最低気温</b><br />{apiRequestUrlMntemsadext00}</p>
                <p><b>APIリクエストURL 最高気温</b><br />{apiRequestUrlMxtemsadext00}</p>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="grix xs1">
        <div className="grix xs5">
          <div className="col-xs5">
            <h4 style={{ width: "100%", borderBottom: "1px solid", marginBottom: "2rem" }}>気象情報</h4>
          </div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最寄りの観測所</div>
          <div className="col-xs3" style={{ fontWeight: "bold" }}>{prefecture} / {point}</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">降水量</div>
          <div className="col-xs3" style={{ fontWeight: "bold" }}>{currentPrecipitation} mm</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最低気温</div>
          <div className="col-xs3" style={{ fontWeight: "bold", color: "#7eb2ff" }}>{minimumTemperature} ℃</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最高気温</div>
          <div className="col-xs3" style={{ fontWeight: "bold", color: "#ff8080" }}>{maximumTemperature} ℃</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">降水量の観測日時</div>
          <div className="col-xs3">{currentTime}</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最低気温の観測日時</div>
          <div className="col-xs3">{currentTimeMinimumTemperature}</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最高気温の観測日時</div>
          <div className="col-xs3">{currentTimeMaximumTemperature}</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">降水量を気象庁から収集した日時</div>
          <div className="col-xs3">{createTime}</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最低気温を気象庁から収集した日時</div>
          <div className="col-xs3">{createTimeMinimumTemperature}</div>
        </div>
        <div className="grix xs5" style={{ marginBottom: "1rem" }}>
          <div className="col-xs2">最高気温を気象庁から収集した日時</div>
          <div className="col-xs3">{createTimeMaximumTemperature}</div>
        </div>
        <div className="grix xs5">
          <div className="col-xs5" style={{ height: "300px" }}>
            <h5 style={{ width: "100%", borderBottom: "1px solid", marginBottom: "2rem" }}>APIが返した値</h5>
            <textarea
              readOnly={true}
              value={util.inspect(weatherData, false, null)}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
